
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";

const VGuideValidate = defineComponent({
  name: "VGuideValidate",
  components: { CModal, CFormInputText },
  setup() {
    useHead({ title: "Validar guia eletrônica | obmed" });

    const store = useStore();
    const router = useRouter();

    const form = reactive({ id: "", hash: "" });
    const loading = reactive({ submit: false });
    const show = reactive({ form: true });

    function handleClose() {
      show.form = false;
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const response = await store.dispatch("validateGuide", { form });
      loading.submit = false;

      if (response?.status === 200)
        router.replace({
          name: "guide-confirm-validation",
          query: { guide: form.id, hash: form.hash },
        });
    }

    return { form, loading, show, handleClose, onSubmit };
  },
});

export default VGuideValidate;
